import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../modules/layout"
import Fotos from "../../modules/m10_fotos"
import Servicios from "../../modules/m08_Services"
import M11FotosBefore from "../../modules/m11_antesydespues"
import Casos from "../../modules/m20_casos"
import Hero from "../../modules/m07_heroCaso"
import datos from "../../data/casos.js"
import Gallery from "../../modules/m13_FullsizeModalFotos"
import img1 from "../../images/puerto-real/g1.jpg"
import img2 from "../../images/puerto-real/g2.jpg"
import img3 from "../../images/puerto-real/g3.jpg"
import img4 from "../../images/puerto-real/g4.jpg"
import img5 from "../../images/puerto-real/g5.jpg"
import antes from "../../images/puerto-real/foto_antes.jpg"
import despues from "../../images/puerto-real/foto_despues.jpg"
import Block from "../../components/block"
import Card from "../../components/cardCaso"
import datacasos from "../../data/casos"

const imgList = [img1, img2, img3, img4, img5]
const data = datos.filter(d => d.nombre === "Puerto Real")[0]

const CasoPage = () => {
  const [galleryOpen, setGalleryOpen] = useState(false)

  const Imgs = useStaticQuery(
    graphql`
      query {
        header: file(relativePath: { eq: "puerto-real/g1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mapa: file(relativePath: { eq: "puerto-real/Puerto_Real.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a1: file(relativePath: { eq: "puerto-real/g1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a2: file(relativePath: { eq: "puerto-real/g3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b1: file(relativePath: { eq: "puerto-real/g4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 840, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b2: file(relativePath: { eq: "puerto-real/g2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 840, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return (
    <Layout
      title={data.seo.title}
      desc={data.seo.title}
      keywords={data.seo.keywords}
      url="casos/puerto-real"
    >
      <Hero
        HeroPhoto={Imgs.header}
        data={data}
        vr360={data.vr360}
        openGallery={() => setGalleryOpen(true)}
        Mapa={Imgs.mapa}
        tagid07360="TM49-PuertoReal-MóduloVista360S-Boton360"
        tagid07fotos="TM50-PuertoReal-MóduloFotosS-BotonFotos"
      />
      <M11FotosBefore
        title="Comparativa"
        subtitle="Así es como se ha transformado el inmueble, la gran diferencia entre el estado incial y la reforma final"
        srcAntes={antes}
        altAntes="Lamentable imagen de local en obras con todo a medio construir"
        srcDespues={despues}
        altDespues="Hermosa vista final del inmueble terminado y decorado con estilo"
        tagidhandler="TM56-PuertoReal-MóduloComparador-ComparadorAntesDespues"
      />

      <Fotos
        Imgs={Imgs}
        vr360={data.vr360}
        d={data}
        openGallery={() => setGalleryOpen(true)}
        tagid10360="TM51-PuertoReal-MóduloVista360I-Boton360"
        tagid10Fotos="TM52-PuertoReal-MóduloFotosI-BotonFotos"
        alt01="Imagen de la cocina"
        alt02="Detalle de la cama"
        alt03="Imagen del baño"
        alt04="Imagen de la cama y la ventana"
      />
      <Servicios />
      <Block>
        <Casos>
          <Card
            datacasos={datacasos[0]}
            tagmanagerid="TM53-PuertoReal-MóduloCasosPracticos-JuanIziar"
            title="De un restaurante a tres viviendas"
          ></Card>
          <Card
            datacasos={datacasos[1]}
            tagmanagerid="TM54-PuertoReal-MóduloCasosPracticos-SanVenancio"
            title="De un local en bruto a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[3]}
            tagmanagerid="TM55-PuertoReal-MóduloCasosPracticos-Zuera"
            title="De una pescadería a un estudio"
          ></Card>
        </Casos>
      </Block>
      <Gallery
        galleryOpen={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        imgGallery={imgList}
        address={data.direccion}
      />
    </Layout>
  )
}

export default CasoPage
